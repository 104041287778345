import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeacherFBEVerificationComponentComponent } from './teacher-fbeverification-component/teacher-fbeverification-component.component';


const routes: Routes = [
    //{ path: '', redirectTo: 'teacher-fbeverification', pathMatch: 'full' },
    { path: 'teacher-fbeverification', component: TeacherFBEVerificationComponentComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
