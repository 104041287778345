<html>

<head>
  <script src="https://kit.fontawesome.com/71c4280437.js" crossorigin="anonymous"></script>
</head>

<body>

  <!--
  This is the stub for the Teacher FBE Verification Page.<br />
  It is activated by a URL parameter "?EmailGUID=[Valid36CharacterGUID]".<br />
  Following is the valid Email GUID: {{EmailGUID}}
  -->

  <div style="text-align:center;">
    <div class="banner">
      <img src="../../assets/images/logo-light.png" />
    </div>
    <div [hidden]="TVFRecord_LoadComplete == true" style="font-size:large; font-weight:bold; padding:8px; margin:4px;">
      Standby: Loading FBE Information...
    </div>

    <div [hidden]="TVFRecord_ButtonsAvailable == false" style="font-size:large; font-weight:bold; padding:8px; margin:4px;">
      Please Approve or Deny the Following FBE by pressing the appropriate button Below:
    </div>
    <div>
      <div [hidden]="TVFRecord_LoadComplete == false" style="border-width:1px; border-style:dashed; border-color:black; padding:8px; margin:4px; font-family:monospace; font-size:medium; text-align:right; display:flex;">
        <div style="width:40%">
          <i>Intern Name:</i>
          <br />
          <i>Duration in Hours:</i>
          <br />
          <i>Location:</i>
          <br />
          <i>FBE Date and Time:</i>
        </div>
        <div style="width:60%; text-align:left;">
          &nbsp;
          <b>{{TVFRecord.internFirstName}} {{TVFRecord.internLastName}}</b>
          <br />
          &nbsp; <b>{{TVFRecord.fbeDuration}}</b>
          <br />
          &nbsp; <b>{{TVFRecord.fbeLocation}}</b>
          <br />
          &nbsp; <b>{{TVFRecord.fbeStartDate | date:"yyyy-MM-dd"}} {{TVFRecord.fbeStartDate | date:"h:mm a"}} to {{TVFRecord.fbeEndDate | date:"h:mm a"}}</b>
        </div>
      </div>
    </div>

    <div [hidden]="TVFRecord_ButtonsAvailable == false" style="padding:8px;">
      <button (click)="ApproveFBE()" style="color:green; font-size:large; cursor:pointer;">Approve FBE</button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <button (click)="DenyFBE()" style="color:red; font-size:large; cursor:pointer;">Deny FBE</button>
    </div>

    <div [hidden]="TVFRecord_Approved == false" style="padding:8px; margin:8px; font-size:x-large; font-family:monospace; color:green; border:dashed; border-width:1px; border-color:green;">
      <b>Status: FBE Approved</b>
      <div style="font-size:small">(No Further Action is necessary at this time.)</div>
    </div>

    <div [hidden]="TVFRecord_Denied == false" style="padding:8px; margin:8px; font-size:x-large; font-family:monospace; color:red; border:dashed; border-width:1px; border-color:red;">
      <b>Status: FBE Denied</b>
      <div style="font-size:small">(No Further Action is necessary at this time.)</div>
    </div>


  </div>

</body>

</html>