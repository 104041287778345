export enum FBEStatusEnum {
    Approved = 1,
    Pending = 2,
    NeedsInfo = 3,
    Denied = 4,
    Verified = 5,
    Saved = 6,
    TeacherDenied = 7,
    NeedsVerification = 8,
    TeacherVerified = 11
}