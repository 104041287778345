import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { GetTVFRecord_Options, TVFRecord, UpdateFBEStatus_Options } from '../models/teacher-fbeverification.model'

@Injectable({
    providedIn: 'root'
})
export class TeacherFBEVerificationService {

    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    apiUrl = '';

  constructor(private httpClient: HttpClient, private configSvc: ConfigService) {
    this.apiUrl = this.configSvc.apiUrl;
    }


    public TVF_UpdateFBEStatus(UpdateFBEStatus_Options: UpdateFBEStatus_Options) {
        return this.httpClient.post<any>(`${this.apiUrl}/v1.0/TVF/UpdateFBEStatus`, UpdateFBEStatus_Options).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public TVF_GetTVFRecord(GetTVFRecord_Options: GetTVFRecord_Options) {
        return this.httpClient.post<any>(`${this.apiUrl}/v1.0/TVF/GetTVFRecord`, GetTVFRecord_Options).pipe(
            retry(3),
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }
}
