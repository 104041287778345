export class TVFRecord
{
    constructor(){}

    fbeId: string = null;

    internId: string = null;
    internFirstName: string = null;
    internLastName: string = null;

    stateCode: string = null

    fbeStatusId: number = null;
    fbeStatusName: string = null;
    fbeType: string = null;

    fbeStartDate: Date = null;
    fbeEndDate: Date = null;

    fbeDuration: string = null;
    fbeLocation: string = null;

    teacherName: string = null;
    teacherPhone: string = null;
    teacherEmail: string = null;

    trainingCourseTypeId: number = null;
}

export class GetTVFRecord_Options
{
    EmailGUID: string = null;
}

export class UpdateFBEStatus_Options
{
    CandidateFBEID: string = null;
    TargetFBEStatusID: string = null;
}
