import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  configuration: any;
  
  constructor(private httpClient: HttpClient) { }

  loadConfigs(): Promise<Object> {
    return this.httpClient.get<any>(window.location.origin + '/assets/config.json')
      .pipe(
        tap(config => {
          this.configuration = config;
        })
      )
      .toPromise();
  }

  get apiUrl() {
    return this.configuration.apiUrl;
  }
}
